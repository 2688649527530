/* FONTS */
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 200;
  font-display: auto;
  src: local("Rubik Light"), local("Rubik-Light"), url("/assets/webfonts/Rubik-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: local("Rubik Regular"), local("Rubik-Regular"), url("/assets/webfonts/Rubik-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-display: auto;
  src: local("Rubik Medium"), local("Rubik-Medium"), url("/assets/webfonts/Rubik-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: local("Rubik Bold"), local("Rubik-Bold"), url("/assets/webfonts/Rubik-Bold.ttf") format("truetype");
}

/* STYLES */
#pageload-overlay-pane {
  transition: opacity 1s;
}

#pageload-please-wait .blinking1 {
  animation: blinkingPoint1 3s infinite;
}

#pageload-please-wait .blinking2 {
  animation: blinkingPoint2 3s infinite;
}

#pageload-please-wait .blinking3 {
  animation: blinkingPoint3 3s infinite;
}

body {
  font-family: "Rubik", "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 15px;
  color: #111111;
  background-color: transparent;
}

h1,
.h1 {
  font-size: 2em;
}

h2,
.h2 {
  font-size: 2em;
}

h3,
.h3 {
  font-size: 1.5em;
}

h4,
.h4 {
  font-size: 1.25em;
}

h5,
.h5 {
  font-size: 1.125em;
}

h6,
.h6 {
  font-size: 1em;
  margin-bottom: 0.125rem;
}

body h1,
body .h1,
body h2,
body .h2,
body h3,
body .h3 {
  font-weight: 900;
}

body h4,
body .h4 {
  font-weight: 500;
}

body h5,
body .h5,
body h6,
body .h6 {
  font-weight: 700;
}

body .lead {
  font-size: 1.125em;
}

body a {
  color: inherit;
  transition: all 300ms ease;
  text-decoration: none;
}

body a:hover {
  color: inherit;
  text-decoration: none;
}

body a.anchor {
  display: block;
  position: relative;
  visibility: hidden;
}

body a.anchor-offset {
  display: block;
  position: relative;
  top: -67px;
  visibility: hidden;
  @media (min-width: 768px) {
    display: block;
    position: relative;
    top: -75px;
    visibility: hidden;
  }
}

.text-light {
  color: #dddddd !important;
}

.text-muted {
  color: #717074 !important;
}

.lazy-bg , .lazy-img {
  background-color: #afafaf0d;
}

.bg-dark .lazy-bg,
.bg-dark .lazy-img,
.bg-lessdark .lazy-bg,
.bg-lessdark .lazy-img {
  background-color: #3333330d;
}

.bg-dark {
  background-color: #111111 !important;
}

.bg-lessdark {
  background-color: #222222 !important;
}

.bg-light {
  background-color: #e4e4e4 !important;
}

.bg-lighter {
  background-color: #f2f2f2 !important;
}

.bg-alternate-cols-dark .bg-alternate-col:nth-child(odd) {
  background-color: #111111 !important;
}

.bg-alternate-cols-dark .bg-alternate-col:nth-child(even) {
  background-color: #222222 !important;
}

// Stripes for 1 column layout
// Upcoming (select by even / odd) and Profiles (select by new, random order)
.bg-alternate-cols .bg-alternate-col:nth-child(odd),
.bg-alternate-cols .bg-alternate-col-ordered.order-1,
.bg-alternate-cols .bg-alternate-col-ordered.order-3,
.bg-alternate-cols .bg-alternate-col-ordered.order-5 {
  background-color: #f2f2f2;
}

.bg-alternate-cols .bg-alternate-col:nth-child(even),
.bg-alternate-cols .bg-alternate-col-ordered.order-2,
.bg-alternate-cols .bg-alternate-col-ordered.order-4,
.bg-alternate-cols .bg-alternate-col-ordered.order-6 {
  background-color: #e4e4e4;
}

// Stripes for 2 column layout
// Upcoming at breakpoint sm
@media (min-width: 576px) {
  .bg-alternate-cols .bg-alternate-col:nth-child(4n),
  .bg-alternate-cols .bg-alternate-col:nth-child(4n-3) {
    background: #f2f2f2;
  }
  .bg-alternate-cols .bg-alternate-col:nth-child(4n-1),
  .bg-alternate-cols .bg-alternate-col:nth-child(4n-2) {
    background: #e4e4e4;
  }
}

// Profiles break at sm
@media (min-width: 576px) {
  .bg-alternate-cols .bg-alternate-col-ordered.order-1,
  .bg-alternate-cols .bg-alternate-col-ordered.order-4,
  .bg-alternate-cols .bg-alternate-col-ordered.order-5 {
    background: #f2f2f2;
  }
  .bg-alternate-cols .bg-alternate-col-ordered.order-2,
  .bg-alternate-cols .bg-alternate-col-ordered.order-3,
  .bg-alternate-cols .bg-alternate-col-ordered.order-6 {
    background: #e4e4e4;
  }
}

// stripes for 3 column layout
@media (min-width: 992px) {
  .bg-alternate-cols .bg-alternate-col:nth-child(6n-1),
  .bg-alternate-cols .bg-alternate-col:nth-child(6n-3),
  .bg-alternate-cols .bg-alternate-col:nth-child(6n-5) {
    background: #f2f2f2;
  }
  .bg-alternate-cols .bg-alternate-col:nth-child(6n),
  .bg-alternate-cols .bg-alternate-col:nth-child(6n-2),
  .bg-alternate-cols .bg-alternate-col:nth-child(6n-4) {
    background: #e4e4e4;
  }
}

@media (min-width: 992px) {
  .bg-alternate-cols .bg-alternate-col-ordered.order-1,
  .bg-alternate-cols .bg-alternate-col-ordered.order-3,
  .bg-alternate-cols .bg-alternate-col-ordered.order-5 {
    background: #f2f2f2;
  }
  .bg-alternate-cols .bg-alternate-col-ordered.order-2,
  .bg-alternate-cols .bg-alternate-col-ordered.order-4,
  .bg-alternate-cols .bg-alternate-col-ordered.order-6 {
    background: #e4e4e4;
  }
}

main {
  padding: 0 0 calc(100vh - 69px - 108px) 0 !important;
  @media (min-width: 768px) {
    padding: 0 0 calc(100vh - 77px - 124px) 0 !important;
  }
}

main #page-background-container {
  z-index: -1 !important;
}

main #page-background {
  background-image: url(/assets/img/background-img.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

main #content {
  @media (min-width: 576px) {
    max-width: 768px;
  }
  @media (min-width: 768px) {
    max-width: 992px;
  }
  @media (min-width: 992px) {
    max-width: 1200px;
  }
  @media (min-width: 1200px) {
    max-width: 1200px;
  }
  margin-right: auto;
  margin-left: auto;
  background-color: transparent;
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
}

main #content a:not(.iconic-link):not(.img-link):not(.footer-link) {
  opacity: 0.65;
}

main #content a:not(.iconic-link):not(.img-link):not(.footer-link):hover {
  opacity: 1;
}

main #content a i.border.rounded-circle {
  border: 1px solid !important;
  transition: all 300ms ease;
  opacity: 1;
}

main #content a:hover i.border.rounded-circle {
  border: 1px solid !important;
  -moz-box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.65);
  -webkit-box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.65);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.65);
  opacity: 1;
}

i.border.fa-fw {
  width: 2.2em;
}

i.fa:not(.border):not(.player-loader-icon),
i.far:not(.border):not(.player-loader-icon),
i.fas:not(.border):not(.player-loader-icon),
i.fab:not(.border):not(.player-loader-icon) {
  font-size: 0.9em;
}

section#contact-section a i.border.rounded-circle {
  width: 2em;
  font-size: 1.2em;
}

section#contact-section a i.fa-2x.border.rounded-circle {
  width: 2em;
  height: 2em;
  font-size: 1.8em;
}

section#contact-section a i.fa-2x.border.rounded-circle::before {
  position: relative;
  top: 4px;
}

section#contact-section a:hover i.border.rounded-circle {
  -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5);
}

.fa-icon-mail::before {
  content: "\f0e0";
}

.fa-icon-fb::before {
  content: "\f39e";
}

.fa-icon-sc::before {
  content: "\f1be";
  position: relative;
  left: -2px;
}

.fa-icon-mc::before {
  content: "\f289";
  position: relative;
  left: -2px;
}

.fa-icon-ht::before {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='13.5' viewBox='0 0 15 15'%3E%3Cpath d='M12.8 2.2c-2.9-2.9-7.7-2.9-10.6 0 -2.9 2.9-2.9 7.7 0 10.6 2.9 2.9 7.7 2.9 10.6 0S15.7 5.1 12.8 2.2zM9.2 11.2c-0.2 0.2-0.8 0.4-0.8 0 0-0.4-0.1-1.9-1.3-3.1C5.9 6.9 4.3 6.7 4 6.7c-0.3 0-0.2-0.6 0-0.8 0.2-0.2 0.3-0.1 0.7-0.1 0 0 2.2 0.3 3.3 1.5 1.2 1.2 1.4 3.2 1.4 3.2C9.3 10.8 9.3 11.1 9.2 11.2zM11.7 10.9c-0.2 0.2-1.1 0.5-1.1 0 0-0.5-0.1-2.8-1.8-4.4 -1.7-1.7-4.1-2-4.5-2C3.8 4.5 4 3.6 4.2 3.4c0.2-0.2 0.5-0.1 0.9-0.1 0 0 3.1 0.4 4.8 2.1C11.5 7 11.8 10 11.8 10 11.8 10.4 11.9 10.7 11.7 10.9z'/%3E%3C/svg%3E");
  position: relative;
  top: 1px;
}

.fa-icon-yt::before {
  content: "\f167";
  position: relative;
  left: -1px;
}

.fa-icon-twitch::before {
  content: "\f1e8";
  position: relative;
  top: 1px;
}

.fa-icon-insta::before {
  content: "\f16d";
}

.fa-icon-telegram::before {
  content:"\f2c6";
}

.fa-icon-ra::before {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='12' viewBox='0 0 16 12'%3E%3Cpolyline points=' 11.2 3 15 7.3 13.7 9 8.2 9 4.9 6 1 6 1 3 5.6 3 8.2 4.6 ' style='fill:none;stroke-linejoin:round;stroke-width:2;stroke:%23111'/%3E%3C/svg%3E");
  position: relative;
  top: 1px;
}

.modal-backdrop.show {
  background-color: #111111;
  opacity: 0.85;
}

.modal .modal-content {
  border-radius: 0;
  background-color: #f5f5f5;
}

.modal .modal-header {
  border-bottom: none;
  padding-bottom: 0;
}

.modal .modal-title {
  font-weight: 700;
}

header nav.navbar > .container-fluid {
  @media (min-width: 576px) {
    max-width: 768px;
  }
  @media (min-width: 768px) {
    max-width: 992px;
  }
  @media (min-width: 992px) {
    max-width: 1200px;
  }
  @media (min-width: 1200px) {
    max-width: 1200px;
  }
}

header nav.navbar {
  font-family: "Rubik";
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

header nav a.navbar-brand,
header nav a.navbar-brand:focus,
header nav a.navbar-brand:active,
header nav a.navbar-brand.active {
  color: #111111;
  opacity: 0.85;
}

header nav a.navbar-brand svg {
  transform: scale(0.86) translateX(-10px);
  @media (min-width: 768px) {
    transform: scale(1) translateX(-1px);
  }
}

header nav a.navbar-brand:hover {
  opacity: 1;
}

header nav .navbar-toggler {
  padding: 0;
  border: none;
  outline: none;
}

header nav .navbar-toggler .navbar-toggler-icon {
  background-image: none;
}

header nav .navbar-toggler.collapsed .bar-top,
header nav .navbar-toggler.collapsed .bar-mid,
header nav .navbar-toggler.collapsed .bar-bottom {
  transform: translate(0, 0);
  transition: all 300ms ease;
}

header nav .navbar-toggler .bar-top {
  transform: translate(30px, 0px);
  transition: all 300ms ease;
}

header nav .navbar-toggler .bar-bottom {
  transform: translate(-30px, 0px);
  transition: all 300ms ease;
}

header nav .navbar-nav .nav-link {
  line-height: 1.3;
  color: #111111;
  opacity: 0.85;
  box-shadow: 0px 2px 0 0px transparent;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
}

header nav .navbar-nav .nav-link:hover,
header nav .navbar-nav .nav-link:focus,
header nav .navbar-nav .nav-link:active,
header nav .navbar-nav .nav-link.active {
  color: #111111;
  opacity: 1;
}

header nav .navbar-nav .nav-link.active {
  box-shadow: 0px 2px 0 0px #111111;
}

#welcome-section {
  height: 100vh;
  background-color: transparent;
}

#welcome-section #welcome-container {
  height: 100vh;
  width: 100%;
}

#welcome-section a {
  opacity: 1;
}

#welcome-section a {
  animation: twitch 9.2s 3.6s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite;
}

#welcome-section a:hover.rolate {
  animation: rolate 2.8s cubic-bezier(0.3, 0.58, 0.72, 0.49) infinite;
}

#welcome-section a:hover .jump-in-arrow.rolate {
  animation: rolate 1.4s cubic-bezier(0.45, 0.05, 0.55, 0.95) infinite;
}

#welcome-section a:hover.pulse {
  animation: pulse 1.4s cubic-bezier(0.43, -0.02, 0.26, 1.32) infinite;
}

#welcome-section a:hover .jump-in-arrow.pulse {
  animation: pulse 1.4s cubic-bezier(0.18, 0.89, 0.32, 1.28) infinite;
}

#welcome-section .move img {
  width: 9rem;
  @media (min-width: 576px) {
    width: 9.5rem;
  }
  @media (min-width: 768px) {
    width: 10rem;
  }
  @media (min-width: 992px) {
    width: 10.5rem;
  }
  @media (min-width: 1200px) {
    width: 11rem;
  }
}

#welcome-section .jump-in-arrow img {
  margin-top: -2px;
  vertical-align: top;
  width: 1.875rem;
  @media (min-width: 576px) {
    width: 2rem;
  }
  @media (min-width: 768px) {
    width: 2.0725rem;
  }
  @media (min-width: 992px) {
    width: 2.125rem;
  }
  @media (min-width: 1200px) {
    width: 2.25rem;
  }
}

#who-section .bg-alternate-col-ordered > .container-fluid,
#who-section #crew-container .container-fluid,
#upcoming-section.bg-alternate-col > .container-fluid {
  max-width: 425px;
}

#who-section .profile-cover-mask,
#upcoming-section .upcoming-cover-mask {
  transition: box-shadow 300ms cubic-bezier(0.25, 0.8, 0.25, 1);
}

#who-section .profile-cover-mask:hover,
#who-section .profile-cover-mask:focus,
#who-section .profile-cover-mask:active,
#who-section .profile-cover-mask.active,
#upcoming-section .upcoming-cover-mask:hover,
#upcoming-section .upcoming-cover-mask:focus,
#upcoming-section .upcoming-cover-mask:active,
#upcoming-section .upcoming-cover-mask.active {
  -moz-box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
}

#who-section .profile-cover-box,
#upcoming-section .upcoming-cover-box {
  position: relative;
  width: 100%; /* desired width */
}

#who-section .profile-cover-box::before,
#upcoming-section .upcoming-cover-box::before {
  content: "";
  display: block;
  padding-top: 100%; /* initial ratio of 1:1*/
}

#who-section .profile-cover-content,
#upcoming-section .upcoming-cover-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

#who-section .profile-cover-foto,
#upcoming-section .upcoming-cover-thumb {
  width: 100%;
  height: 100%;
  background-size: cover;
}

#upcoming-section .past .overlay-pattern {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.025;
  width: 100%;
  height: 100%;
  background-image: url(/assets/img/overlay-pattern.png);
  background-size: cover;
  mix-blend-mode: difference;
}

#upcoming-section .upcoming-cover-thumb,
#upcoming-section .overlay-pattern {
  filter: none;
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  -o-filter: grayscale(0%);
  -webkit-transition: all 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
  -moz-transition: all 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
  -ms-transition: all 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
  -o-transition: all 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

#upcoming-section .past .upcoming-cover-thumb {
  filter: none;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
}

#upcoming-section a:hover .upcoming-cover-thumb,
#upcoming-section a:focus .upcoming-cover-thumb,
#upcoming-section a:active .upcoming-cover-thumb,
#upcoming-section a.active .upcoming-cover-thumb,
#upcoming-section a:hover .overlay-pattern,
#upcoming-section a:focus .overlay-pattern,
#upcoming-section a:active .overlay-pattern,
#upcoming-section a.active .overlay-pattern {
  filter: none;
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  -o-filter: grayscale(0%);
  transform: scale(1.02);
}

#upcoming-section a:hover .overlay-pattern,
#upcoming-section a:focus .overlay-pattern,
#upcoming-section a:active .overlay-pattern,
#upcoming-section a.active .overlay-pattern {
  opacity: 0;
}

#upcoming-section .cancelled {
  text-decoration: line-through;
}

.ekko-lightbox-item img {
  -moz-box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
}

#radio-section #radio-player-section {
  background-position: bottom right;
  background-size: cover;
  background-repeat: no-repeat;
}

#radio-section #radio-player-section-title {
  min-height: 236px;
}

#radio-section #radio-play-button:disabled {
  opacity: 0.5;
}

#radio-section .btn-group-sm > .btn {
  font-size: 0.75rem;
}

#radio-section .btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.3;
  border-radius: 0.3rem;
}

#radio-section .btn-group-sm > .btn:hover:not(:disabled),
#radio-section .btn-group-sm > .btn:focus:not(:disabled) {
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.06) 25%,
    rgba(255, 255, 255, 0) 75%
  );
}

header nav #nav-link-onair-indicator.text-danger::before,
#radio-section #onair-indicator.text-danger::before {
  color: #cc4755;
  border-radius: 50%;
  animation: glowing-red 1s cubic-bezier(0.86, 0, 0.07, 1) infinite,
    blinking-red 1s cubic-bezier(0.86, 0, 0.07, 1) infinite;
  animation-delay: 1000ms;
}

header nav #nav-link-onair-indicator {
  font-size: 0.75rem;
  position: relative;
  top: -1px;
  opacity: 0.85;
}

#radio-section button#radio-onair {
  opacity: 0.85;
}

#radio-section #radio-player-section-volume button:focus {
  box-shadow: none;
}

#radio-section #radio-player-section {
  -moz-box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
}

#who-section .profile-cover-foto {
  filter: none;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -webkit-transition: filter 300ms ease;
  -moz-transition: filter 300ms ease;
  -ms-transition: filter 300ms ease;
  -o-transition: filter 300ms ease;
  transition: filter 300ms ease;
}

#who-section .profile-cover-foto:hover,
#who-section .profile-cover-foto:focus,
#who-section .profile-cover-foto:active,
#who-section .profile-cover-foto.active {
  filter: none;
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  -o-filter: grayscale(0%);
}

#upcoming-section ul,
#radio-section ul {
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0.65em;
}

#upcoming-section ul li,
#radio-section ul li {
  display: inline-block;
}

#upcoming-section li:not(:last-child)::after,
#radio-section li:not(:last-child)::after {
  content: "\25AA";
  margin: 0 0.2rem 0 0.4rem;
}

#who-section .iframe-container {
  width: 100%;
  height: 124px;
}

#who-section .iframe-container .preload {
  position: absolute;
  margin-right: 3rem;
  z-index: 5;
}

#who-section .iframe-container .player-loader-top {
  height: 62px;
}

#who-section .iframe-container .player-loader-bottom {
  height: 62px;
}

#who-section .iframe-container .player-loader-icon {
  font-size: 2.2em;
  margin: 14px 8px 16px 10px;
}

#who-section .iframe-container .player-loader-advise {
  margin: 0 15px;
}

#who-section .iframe-container .player-loader-title h5 {
  line-height: 0.5;
}

#who-section .iframe-container hr.player-mid-border {
  border-top: 1px solid #111111;
  position: relative;
  top: -70px;
}

#who-section .iframe-container iframe {
  border-top: 2px solid #111111;
  border-bottom: 2px solid #111111;
  border-left: none;
  border-right: none;
  mix-blend-mode: darken;
}

#stories-section .iframe-container {
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

#stories-section .iframe-container.border-dotted {
  border: 1px dotted #f5f5f510;
  background-color: #f5f5f504;
}

#stories-section .iframe-container .player-loader-icon {
  font-size: 2.75em;
  margin: 0 0 5px 0;
}

#stories-section .iframe-container .player-loader-box {
  background-color: #111111dd;
}

#stories-section .row .carousel-txt-col,
#stories-section .row .carousel-img-col {
  overflow: hidden;
}

#stories-section .row .carousel-img-col img,
#stories-section .row .carousel-img-col iframe {
  background-color: transparent;
  object-fit: contain;
  -moz-box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
}

#stories-section ol.carousel-indicators {
  position: initial;
  @media (min-width: 768px) {
    position: absolute;
  }
}

#stories-section ol.carousel-indicators li {
  background-color: #f5f5f5bb;
}

#stories-section ol.carousel-indicators li:first-child {
  margin-left: 0;
}

#stories-section ol.carousel-indicators li:last-child {
  margin-right: 0;
}

#stories-section a.carousel-control-prev,
#stories-section a.carousel-control-next {
  width: 15%;
  opacity: 0 !important;
  margin: 20% 0;
  background-color: #22222222;
}

#stories-section a.carousel-control-prev:hover,
#stories-section a.carousel-control-next:hover {
  opacity: 1 !important;
}

#support-section ul {
  margin: 0;
}

footer#page-footer > #footer-content.container-fluid {
  padding-left: 0;
  padding-right: 0;
  @media (min-width: 576px) {
    max-width: 768px;
  }
  @media (min-width: 768px) {
    max-width: 992px;
    padding-left: 15px;
    padding-right: 15px;
  }
  @media (min-width: 992px) {
    max-width: 1200px;
  }
  @media (min-width: 1200px) {
    max-width: 1200px;
  }
}

footer#page-footer #footer-content a {
  letter-spacing: 0.025em;
  font-size: 80%;
}

footer#page-footer .modal {
  padding: 0 0 0.5rem 0;
}

footer#page-footer .modal p {
  padding: 0 0 0.5rem 0;

  &:last-child {
    margin: 0;
  }
}

footer#page-footer .modal h4 {
  padding: 0 0 0.25rem 0;
}

footer#page-footer .modal h5 {
  padding: 0 0 0.15rem 0;
}

footer#page-footer .license-img {
  background-image: url(/assets/img/cc-by-sa-88x31.png);
  background-repeat: no-repeat;
  background-position: center center;
  width: 90px;
  height: 33px;
}

/* ANIMATIONS */

@keyframes blinkingPoint1 {
  0% {
    color: transparent;
  }
  24% {
    color: transparent;
  }
  25% {
    color: inherit;
  }
  99% {
    color: inherit;
  }
  100% {
    color: transparent;
  }
}

@keyframes blinkingPoint2 {
  0% {
    color: transparent;
  }
  49% {
    color: transparent;
  }
  50% {
    color: inherit;
  }
  99% {
    color: inherit;
  }
  100% {
    color: transparent;
  }
}

@keyframes blinkingPoint3 {
  0% {
    color: transparent;
  }
  74% {
    color: transparent;
  }
  75% {
    color: inherit;
  }
  99% {
    color: inherit;
  }
  100% {
    color: transparent;
  }
}

@keyframes twitch {
  0% {
    transform: translate(0, 0);
  }
  36% {
    transform: translate(0, 0);
  }
  39% {
    transform: translate(0, 2px);
  }
  40% {
    transform: translate(0, 1px);
  }
  41% {
    transform: translate(0, 4px);
  }
  44% {
    transform: translate(0, 0);
  }
  75% {
    transform: translate(0, 0);
  }
  76% {
    transform: translate(0, 4px);
  }
  77% {
    transform: translate(0, 2px);
  }
  78% {
    transform: translate(0, 6px);
  }
  79% {
    transform: translate(0, 2px);
  }
  80% {
    transform: translate(0, 6px);
  }
  83% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes rolate {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  25% {
    transform: translate(0, 25px) rotate(-1deg);
  }
  50% {
    transform: translate(0, -5px) rotate(1deg);
  }
  75% {
    transform: translate(0, 30px) rotate(2deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}

@keyframes pulse {
  0% {
    transform: translate(0, 0) scale(1);
  }
  15% {
    transform: translate(0, 1px) scale(1.06);
  }
  25% {
    transform: translate(0, 0) scale(1.025);
  }
  35% {
    transform: translate(0, 1px) scale(1.05);
  }
  60% {
    transform: translate(0, 0) scale(1);
  }
  100% {
    transform: translate(0, 0) scale(1);
  }
}

@keyframes blinking-red {
  20% {
    color: #cc4755;
  }
  50% {
    color: #dc3545;
  }
  80% {
    color: #cc4755;
  }
}

@keyframes glowing-red {
  10% {
    box-shadow: 0px 0px 0px #dc354580;
  }
  50% {
    box-shadow: 0px 2px 10px #dc354599;
  }
  90% {
    box-shadow: 0px 0px 0px #dc354580;
  }
}
